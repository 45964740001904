
<template>
<b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
  <div>

     <validation-observer ref="simpleRules">
        <b-form
          @submit.prevent
        >

      <b-card title="Informations générales">
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Nom"
              label-for="basicInput"
            >
            <validation-provider
              #default="{ errors }"
              name="Nom"
              rules="required"
            >
              <b-form-input
                id="basicInput"
                v-model="form.name"
                :plaintext="readOnly"
                placeholder="Nom du produit"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
            <br />
            <b-form-group
              label="Nom de marque"
              label-for="basicInput"
            >
           
              <b-form-input
                id="basicInput"
                v-model="form.brand_name"
                :plaintext="readOnly"
                placeholder="Nom de la marque"
               
              />
             
            </b-form-group>
            <br />
            <b-form-group
              label="Collaboration"
              label-for="Collaboration"
            >
              <b-form-checkbox
                v-model="form.collaboration"
                
                class="custom-control-primary"
              >
               Collaboration
              </b-form-checkbox>
            </b-form-group>

              <br />
            <b-form-group
              label="Collection BETA"
              label-for="Collection BETA"
            >
              <b-form-checkbox
                v-model="form.beta_collection"
                
                class="custom-control-primary"
              >
              Collection BETA
              </b-form-checkbox>
            </b-form-group>


            <b-form-group
              label="Créateur"
              label-for="Créateur"
            >

            <v-select
                v-model="form.product_owner"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="userBrandChoices"
                
              />
         
              </b-form-group>

              <b-form-group
              label="Comission (%)"
              label-for="Comission (%)"
            >
           
              <b-form-input
                id="Comission"
                v-model="form.comission"
                :plaintext="readOnly"
                type="number"
                step="0.01"
                placeholder="Comission (%)"
               
              />
           
         
            </b-form-group>

            <b-form-group
              label="Nombre de tailles"
              label-for="Nombre de tailles"
            >
           
              <b-form-input
                id="Nombre de tailles"
                v-model="form.size_nb"
                :plaintext="readOnly"
                type="number"
                step="1"
                placeholder="Nombre de tailles"
               
              />
           
         
            </b-form-group>
          </b-col>

        
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >

            <b-form-group
              label="Stock"
              label-for="Stock"
            >
            <validation-provider
              #default="{ errors }"
              name="Stock"
              rules="required|integer"
            >
              <b-form-input
                id="Stock"
                v-model="form.stock"
                :plaintext="readOnly"
                type="number"
                step="1"
                :state="errors.length > 0 ? false:null"
                placeholder="Stock produit"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <draggable v-model="basic_photos" group="basic_photos" @start="drag=true" @end="drag=false">

            <b-form-group v-for="(basic_photo,index) in basic_photos" :key="index"
              :label="'Photo '+ formatIndex(index)"
              label-for="Photo 1"
            >
              
                <b-media vertical-align="top" v-if="basic_photo!==null">
                  <template #aside>
                    <b-img
                      :src="basic_photo"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removeBasicPhoto(index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>

            
            <b-form-file v-else
                v-model="file[index]"
                :state="Boolean(file[index])"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAdded($event, index)"
            />
            </b-form-group>

            </draggable>

            <a @click="addBasicPhoto()">Ajouter une photo</a>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group 
                :label="'Photo hover'"
                label-for="Photo hover"
              >
                <b-media vertical-align="top" v-if="photo_hover!==null">
                  <template #aside>
                    <b-img
                      :src="photo_hover"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removePhotoHover()"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
                v-model="filePhotoHover"
                :state="Boolean(filePhotoHover)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedPhotoHover($event)"
            />
            </b-form-group>

            <b-form-group 
                :label="'Photo solar'"
                label-for="Photo solar"
              >
                <b-media vertical-align="top" v-if="photo_solar!==null">
                  <template #aside>
                    <b-img
                      :src="photo_solar"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removePhotoSolar()"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
                v-model="filePhotoSolar"
                :state="Boolean(filePhotoSolar)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedPhotoSolar($event)"
            />
            </b-form-group>

            <b-form-group 
                :label="'Photo solar hover'"
                label-for="Photo solar hover"
              >
                <b-media vertical-align="top" v-if="photo_solar_hover!==null">
                  <template #aside>
                    <b-img
                      :src="photo_solar_hover"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removePhotoSolarHover()"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
                v-model="filePhotoSolarHover"
                :state="Boolean(filePhotoSolarHover)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedPhotoSolarHover($event)"
            />
            </b-form-group>

          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >

          <b-form-group
              label="Position gravure"
              label-for="Position gravure"
            >

            <!-- <v-select
                v-model="form.gravure_position_x"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="gravureChoices"
                
              />
          -->
             X (percent) :
              <b-form-input
                id="Prix"
                v-model="form.gravure_position_x_new"
                :plaintext="readOnly"
                type="text"
                
                placeholder="0"
              
              />
            Y (percent) :
              <b-form-input
                id="Prix"
                v-model="form.gravure_position_y"
                :plaintext="readOnly"
                type="text"
                step="0.01"
                placeholder="0"
              
              />
              Angle (degres)
              <b-form-input
                id="Prix"
                v-model="form.gravure_position_angle"
                :plaintext="readOnly"
                type="text"
                step="0.01"
                placeholder="0"
              
              /> 
            
            </b-form-group>

            <b-form-group
              label="Fichier STL (nom)"
              label-for="Fichier STL (nom)"
            >

            <!-- <v-select
                v-model="form.gravure_position_x"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="gravureChoices"
                
              />
          -->
            
              <b-form-input
                id="Fichier STL (nom)"
                v-model="form.file1"
                :plaintext="readOnly"
                type="text"
                
                placeholder="Nom fichier"
              
              />
            
            
            </b-form-group>

            <b-form-group
              label="Ordre d'affichage"
              label-for="Ordre d'affichage"
            >
           
            
              <b-form-input
                id="Ordre d'affichage"
                v-model="form.order_display"
                :plaintext="readOnly"
                type="number"
                step="1"
                placeholder="Ordre d'affichage"
               
              />
             
            </b-form-group>


            <b-form-group
              label="Forme"
              label-for="Forme"
            >
              <v-select
                v-model="shape"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="shapeChoices"
              />
            </b-form-group>

             <b-form-group
              label="Genre"
              label-for="Genre"
            >
              <v-select
                v-model="gender"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="genderChoices"
              />
            </b-form-group>


            <b-form-group
              label="Plaquettes nez"
              label-for="Forme"
            >
              <v-select
                v-model="nose"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="noseChoices"
              />
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Prix"
              label-for="Prix"
            >
            <validation-provider
              #default="{ errors }"
              name="Prix"
              rules="required"
            >
              <b-form-input
                id="Prix"
                v-model="form.price"
                :plaintext="readOnly"
                type="number"
                step="0.01"
                placeholder="Prix"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>

            <b-form-group
              label="Coût de production"
              label-for="Coût de production"
            >
           
              <b-form-input
                id="Coût de production"
                v-model="form.production_price"
                :plaintext="readOnly"
                type="number"
                step="0.01"
                placeholder="Coût de production"
               
              />
           
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >


          <b-form-group v-if="form.type=='collaboration' || form.type=='collection'"
              label="Sur-mesure"
              label-for="Sur-mesure"
            >
              <b-form-checkbox
                v-model="form.custom_fit"
                
                class="custom-control-primary"
              >
              Sur-mesure
              </b-form-checkbox>
            </b-form-group>


          <b-form-group v-if="form.type=='collaboration' || form.type=='collection'"
              label="Actif ?"
              label-for="Actif ?"
            >
              <b-form-checkbox
                v-model="form.active"
                
                class="custom-control-primary"
              >
              Actif ?
              </b-form-checkbox>
            </b-form-group>

          <b-form-group v-if="form.type=='collaboration' || form.type=='collection'"
              label="Gravure"
              label-for="Gravure"
            >
              <b-form-checkbox
                v-model="form.engraving"
                
                class="custom-control-primary"
              >
              Gravure
              </b-form-checkbox>
            </b-form-group>
          <b-form-group v-if="form.type=='collaboration' || form.type=='collection'"
              label="Solaire"
              label-for="Solaire"
            >
              <b-form-checkbox
                v-model="form.solar"
                
                class="custom-control-primary"
              >
                Lunettes solaires
              </b-form-checkbox>
            </b-form-group>

            <b-form-group v-if="form.type=='collaboration' || form.type=='collection'"
              label="Optique"
              label-for="Optique"
            >
              <b-form-checkbox
                v-model="form.optic"
                
                class="custom-control-primary"
              >
                Lunettes optiques
              </b-form-checkbox>
            </b-form-group>

            <b-form-group
              label="Teinte par défaut"
              label-for="Teinte par défaut"
            >
           
              <v-select
                v-model="form.default_tint"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="tintChoices"
              
              />
            
            </b-form-group>

            <b-form-group
              label="Couleur par défaut"
              label-for="Couleur par défaut"
            >
           
              <v-select
                v-model="form.default_color"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="colorChoices"
              
              />
            
            </b-form-group>

          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Type"
              label-for="Type"
            >
            <validation-provider
              #default="{ errors }"
              name="Type"
              rules="required"
            >
              <v-select
                v-model="form.type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                label="title"
                :options="typeChoices"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
          
        </b-row>
        <b-row>
          <b-col
            md="12"
            xl="12"
            class="mb-1"
          >
         
            <label for="textarea-default">Description</label>
            <b-form-textarea
              v-model="form.description"
              id="textarea-default"
              placeholder="Description produit"
            
              rows="3"
            />

          
          </b-col>
        </b-row>
        <b-row>
        <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Contient plusieurs variations"
              label-for="Contient plusieurs variations"
            >
              <b-form-checkbox
                v-model="hasVariations"
                class="custom-control-primary"
              >
                Contient plusieurs variations
              </b-form-checkbox>
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="ID Unity"
              label-for="Stock"
            >
             <validation-provider
              #default="{ errors }"
              name="ID Unity"
              rules="integer"
            >
              <b-form-input
                id="Stock"
                v-model="form.unity_id"
                :plaintext="readOnly"
                type="number"
                :state="errors.length > 0 ? false:null"
                step="1"
                placeholder="ID Unity"
              />
               <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>

      <b-card title="Informations sur les tailles">
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group v-for="(size,index) in sizes" :key="size.id"
              :label="'Dimensions de la lunette taille '+size.label"
              label-for="basicInput"
            >
              <b-form-input v-if="glass_dimensions[index]"
                id="basicInput"
                v-model="glass_dimensions[index].width"
                :plaintext="readOnly"
                placeholder="Largeur"
              />
              <br />
               <b-form-input v-if="glass_dimensions[index]"
                id="basicInput"
                v-model="glass_dimensions[index].height"
                :plaintext="readOnly"
                placeholder="Hauteur"
              />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >

            <b-form-group v-for="(size,index) in sizes" :key="size.id"
              :label="'Fichiers 3D de la lunette taille '+size.label"
              label-for="Fichiers 3D de la lunette"
            >
            <div v-if="glass_files[index]">
              <b-media vertical-align="top" v-if="glass_files[index].file!==null">
                  <template #aside>
                    <b-img
                      :src="basic_photo"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removeFile3D(index)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
              
                <b-form-file v-else
                    v-model="glass_files[index].file"
                    :state="Boolean(glass_files[index].file)"
                    placeholder="Choisissez ou faites glisser un fichier..."
                    drop-placeholder="Déposer ici ..."
                    @change="file3DAdded($event, index)"
                />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <b-card v-if="(form.type=='collaboration' || form.type=='accessoire') && hasVariations==true"  title="Variations">
        <b-row v-for="(variation, index) in product_variations" :key="index">
        <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Nom"
              label-for="Nom"
            >
              <b-form-input
                v-model="product_variations[index].name"
                id="Nom"
                :plaintext="readOnly"
                step="0.01"
                placeholder="Nom de la variation"
              
              >
              </b-form-input>
              
            </b-form-group>

            <b-button
              size="sm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                class="btn-icon"
                @click="removeVariation(index)"
              >
                  Supprimer la variation
              </b-button>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Stock"
              label-for="Stock"
            >
              <b-form-input
                id="Stock"
                v-model="product_variations[index].stock"
                :plaintext="readOnly"
                type="number"
                step="0.01"
                placeholder="Stock produit"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Prix"
              label-for="Prix"
            >
              <b-form-input
                id="Prix"
                v-model="product_variations[index].price"
                :plaintext="readOnly"
                type="number"
                step="0.01"
                placeholder="Prix"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >

          <draggable v-model="variation_photos[index]" group="variation_photos" @start="drag=true" @end="drag=false">
            <b-form-group v-for="(variation_photo,indexphoto) in variation_photos[index]" :key="indexphoto"
              :label="'Photo '+ formatIndex(indexphoto)"
              label-for="Photo 1"
            >
              
                <b-media vertical-align="top" v-if="variation_photo!==null">
                  <template #aside>
                    <b-img
                      :src="variation_photo"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removeVariationPhoto(index, indexphoto)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>

            
            <b-form-file v-else
                v-model="file_variation[index][indexphoto]"
                :state="Boolean(file_variation[index][indexphoto])"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedVariation($event, index, indexphoto)"
            />
            </b-form-group>

          </draggable>
          <b-button @click="addVariationPhoto(index)"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="outline-dark"
            >
              Ajouter une photo
            </b-button>

          </b-col>
          
        </b-row>
        <b-row v-if="product_variations==null || product_variations.length==0">
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          Aucune variation pour le moment
          </b-col>
        </b-row>
        <b-button @click="addProductVariation()"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="outline-primary"
            >
              Ajouter une variation
            </b-button>
        
      </b-card>
      <b-card v-if="form.type=='collaboration' || form.type=='collection'"  title="Contenu page produit">
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Logo produit"
              label-for="Photo"
            >
            <b-media vertical-align="top" v-if="file_product_photo!==null">
                  <template #aside>
                    <b-img
                      :src="file_product_photo"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removePhotoCollaboration(9)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media> 
            <b-form-file v-else
                v-model="file_product_photo"
                :state="Boolean(file_product_photo)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedPhoto($event,9)"
            />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Titre 1"
              label-for="basicInput"
            >
              <b-form-input
                id="basicInput"
                v-model="productContentForm.title1"
                :plaintext="readOnly"
                placeholder="Nom du produit"
              />
            </b-form-group>
          </b-col>
      
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Texte 1"
              label-for="Texte 1"
            >
        
              <b-form-textarea
                v-model="productContentForm.text1"
                id="textarea-default"
                placeholder="Texte 1 produit"
                rows="3"
              />
            
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Photo 1"
              label-for="Photo"
            >
            <b-media vertical-align="top" v-if="file_photo1!==null">
                  <template #aside>
                    <b-img
                      :src="file_photo1"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removePhotoCollaboration(1)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
                v-model="file_photo1"
                :state="Boolean(file_photo1)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedPhoto($event,1)"
            />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Titre 2"
              label-for="basicInput"
            >
              <b-form-input
                id="basicInput"
                v-model="productContentForm.title2"
                :plaintext="readOnly"
                placeholder="Titre 2"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Texte 2"
              label-for="Texte 1"
            >
        
              <b-form-textarea
                v-model="productContentForm.text2"
                id="textarea-default"
                placeholder="Texte 1 produit"
                rows="3"
              />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Photo 2"
              label-for="Photo"
            >
            <b-media vertical-align="top" v-if="file_photo2!==null">
                  <template #aside>
                    <b-img
                      :src="file_photo2"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removePhotoCollaboration(2)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
                v-model="file_photo2"
                :state="Boolean(file_photo2)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedPhoto($event,2)"
            />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col v-if="form.type=='collaboration'"
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Titre 3"
              label-for="basicInput"
            >
              <b-form-input
                id="basicInput"
                v-model="productContentForm.title3"
                :plaintext="readOnly"
                placeholder="Titre 3"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Photo 3"
              label-for="Photo"
            >
            <b-media vertical-align="top" v-if="file_photo3!==null">
                  <template #aside>
                    <b-img
                      :src="file_photo3"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removePhotoCollaboration(3)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>

            <b-form-file v-else
                v-model="file_photo3"
                :state="Boolean(file_photo3)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedPhoto($event,3)"
            />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Photo 4"
              label-for="Photo"
            >
            <b-media vertical-align="top" v-if="file_photo4!==null">
                  <template #aside>
                    <b-img
                      :src="file_photo4"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removePhotoCollaboration(4)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
                v-model="file_photo4"
                :state="Boolean(file_photo4)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedPhoto($event,4)"
            />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="form.type=='collaboration'">
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Description monture"
              label-for="Texte 1"
            >
        
              <b-form-textarea
                v-model="productContentForm.mount_description"
                id="textarea-default"
                placeholder="Description monture"
                rows="3"
              />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Description dimensions"
              label-for="Texte 1"
            >
        
              <b-form-textarea
                v-model="productContentForm.dimensions_description"
                id="textarea-default"
                placeholder="Description dimensions"
                rows="3"
              />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Description verres"
              label-for="Texte 1"
            >
        
              <b-form-textarea
                v-model="productContentForm.lense_description"
                id="textarea-default"
                placeholder="Description verres"
                rows="3"
              />
            
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="form.type=='collaboration'">
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
          <b-form-group
              label="Description Packaging"
              label-for="Texte 1"
            >
        
              <b-form-textarea
                v-model="productContentForm.packaging_description"
                id="textarea-default"
                placeholder="Description Packaging"
                rows="3"
              />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Description charnière"
              label-for="Description charnière"
            >
        
              <b-form-textarea
                v-model="productContentForm.hinge_description"
                id="textarea-default"
                placeholder="Description charnière"
                rows="3"
              />
            
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Photo hover"
              label-for="Photo"
            >
            <b-media vertical-align="top" v-if="file_photo_hover!==null">
                  <template #aside>
                    <b-img
                      :src="file_photo_hover"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removePhotoCollaboration(5)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
                v-model="file_photo_hover"
                :state="Boolean(file_photo_hover)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedPhoto($event,5)"
            />
            </b-form-group>
          </b-col>
          
        </b-row>
        <b-row v-if="form.type=='collaboration'">
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Photo hero (background)"
              label-for="basicInput"
            >
            <b-media vertical-align="top" v-if="file_background!==null">
                  <template #aside>
                    <b-img
                      :src="file_background"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removePhotoCollaboration(6)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
              <b-form-file v-else
                v-model="file_background"
                :state="Boolean(file_background)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedPhoto($event,6)"
            />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Logo 1"
              label-for="Texte 1"
            >
            <b-media vertical-align="top" v-if="file_logo1!==null">
                  <template #aside>
                    <b-img
                      :src="file_logo1"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removePhotoCollaboration(7)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media>
            <b-form-file v-else
                v-model="file_logo1"
                :state="Boolean(file_logo1)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedPhoto($event,7)"
            />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Logo 2"
              label-for="Photo"
            >
            <b-media vertical-align="top" v-if="file_logo2!==null">
                  <template #aside>
                    <b-img
                      :src="file_logo2"
                      blank-color="#ccc"
                      width="64"
                      alt="placeholder"
                      
                    />
                  </template>
                  
                  <b-card-text class="mb-0">
                
                    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      @click="removePhotoCollaboration(8)"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
                  </b-card-text>
                </b-media> 
            <b-form-file v-else
                v-model="file_logo2"
                :state="Boolean(file_logo2)"
                placeholder="Choisissez ou faites glisser un fichier..."
                drop-placeholder="Déposer ici ..."
                @change="fileAddedPhoto($event,8)"
            />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="form.type=='collaboration'">
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Spécification 1"
              label-for="basicInput"
            >
              <b-form-input
                id="basicInput"
                v-model="productContentForm.spec1"
                :plaintext="readOnly"
                placeholder="Spec 1"
              />
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Spécification 2"
              label-for="Texte 1"
            >
              <b-form-input
                id="basicInput"
                v-model="productContentForm.spec2"
                :plaintext="readOnly"
                placeholder="Spec 2"
              />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Spécification 3"
              label-for="Photo"
            >
            <b-form-input
                id="basicInput"
                v-model="productContentForm.spec3"
                :plaintext="readOnly"
                placeholder="Spec 3"
              />
            </b-form-group>
          </b-col>
        </b-row>


    </b-card>
    <b-card title="Options de personnalisation">
      
<!-- 
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Couleurs"
              label-for="Couleurs"
            >
        
              <v-select
        
                v-model="colorsSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
              
                :closeOnSelect="false"
                label="title"
                :dropdown-should-open="false"
                :options="colorChoices"
              >
                <template slot="option" slot-scope="option">
                  <img :src="option.cardImage" width='15' />
                  {{ option.title }}
                </template>
              </v-select>
        
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Textures"
              label-for="Textures"
            >
            <v-select
              v-model="texturesSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :closeOnSelect="false"
              label="title"
              :options="textureChoices"
            >
            <template slot="option" slot-scope="option">
                  <img :src="option.cardImage" width='15' />
                  {{ option.title }}
                </template>

                </v-select>
            
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Teintes"
              label-for="Teintes"
            >
            <v-select
              v-model="tintsSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :closeOnSelect="false"
              label="title"
              :options="tintChoices"
            >
            <template slot="option" slot-scope="option">
              <img :src="option.cardImage" width='15' />
              {{ option.title }}
            </template>
                </v-select>
            
            </b-form-group>
          </b-col>
        </b-row> -->
        <b-row>
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Branches"
              label-for="Branches"
            >
            <v-select
              v-model="branchsSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :closeOnSelect="false"
              label="title"
              :options="branchChoices"
            />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Types de verres"
              label-for="Types de verres"
            >
            <v-select
              v-model="glassTypesSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :closeOnSelect="false"
              label="title"
              :options="glassTypeChoices"
            />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Options de verres"
              label-for="Options de verres"
            >
            <v-select
              v-model="glassOptionsSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :closeOnSelect="false"
              label="title"
              :options="glassOptionChoices"
            />
            
            </b-form-group>
          </b-col>

        </b-row>

        <b-row>
          
          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Affinements"
              label-for="Affinements"
            >
            <v-select
              v-model="refinementsSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :closeOnSelect="false"
              label="title"
              :options="refinementChoices"
            />
            
            </b-form-group>
          </b-col>

          <b-col
            md="6"
            xl="4"
            class="mb-1"
          >
            <b-form-group
              label="Traitements"
              label-for="Traitements"
            >
            <v-select
              v-model="processingsSelected"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              multiple
              :closeOnSelect="false"
              label="title"
              :options="processingChoices"
            />
            
            </b-form-group>
          </b-col>

         

        </b-row>
        </b-card>

    <b-card v-if="glass!=null" title="Model parts">
     <table class="table" v-if="model_parts.length>0">
      <th>
        <td>Nom</td>
        <td>Ordre</td>
        <td></td>
      </th>
      <tr v-for="model_part in model_parts" >
        <td>{{ model_part.name }}</td>
        <td>{{ model_part.layer_order }}</td>
        <td> <b-button 
     @click="$router.push({name:'editModelPart', params: { id: model_part.id }})"
    >
    Modifier
    </b-button>
    <b-button
                    size="sm"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="btn-icon"
                      v-b-modal.modal-danger @click="selectedItem=model_part.id"
                    >
                      <feather-icon icon="TrashIcon" />
                    </b-button>
  </td>
      </tr>
     </table>

     <b-button 
     @click="$router.push({name:'addModelPart', params: { id: glass.id }})"
    >
    Ajouter un nouveau
    </b-button>
    </b-card> 

   </b-form>

  </validation-observer>



  <b-button v-if="edition==false"
     @click.prevent="createGlass"
    >
    Enregistrer
    </b-button>

    <b-button v-else
     @click.prevent="updateGlass"
    >
    Enregistrer
    </b-button>
  </div>
  <b-modal
          id="modal-danger"
          cancel-variant="outline-secondary"
          cancel-title="Non"
          ok-variant="danger"
          ok-title="Oui"
          @ok="deleteModelPart(selectedItem)"
          modal-class="modal-danger"
          centered
          title="Êtes-vous sûr ?"
        >
          <b-card-text>
            Attention, cette action est irréversible ! Êtes-vous sûr de vouloir supprimer ce model part ?
          </b-card-text>
        </b-modal>
  </b-overlay>

 

</template>

<script>
import { BButton,BFormInput, BRow, BForm, BCol,BOverlay, BFormGroup,BFormFile, BCard, BCardText, BMedia, BImg, BFormCheckbox, BFormTextarea, BSpinner} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import {APIRequest,Utils,Auth} from '@simb2s/senseye-sdk'
import Ripple from 'vue-ripple-directive'
import draggable from 'vuedraggable'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, email } from '@validations'


export default {
  name: 'GlassForm',
  components: {
    BButton,
    BCardText,
    BMedia, 
    BOverlay,
    BImg,
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BFormFile,
    BCard,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    BSpinner,
    draggable,
    ValidationProvider,
    ValidationObserver,
    BForm
  },
  directives: {
    Ripple,
  },
  data() {
      return {
        selectedItem:null,
          loading:true,
          sizes:[],
          gender:null,
          nose:null,
          shape:null,
          glass_dimensions:[],
          glass_files:[],
          user_brands:null,
          userBrandChoices:[],
          colorsSelected:[],
          texturesSelected:[],
          textureStylesSelected:[],
          tintsSelected:[],
          branchsSelected:[],
          branchesModels:[],
          glassTypesSelected:[],
          glassOptionsSelected:[],
          refinementsSelected:[],
          processingsSelected:[],
          productVariationId:null,
          edition:false,
          loaded:false,
          currentBase64:null,
          hasVariations:false,
          product_variations:null,
          file_variation:[],
          file: [null],
          basic_photos:[null],
          variation_photos:[],
          file_photo1: null,
          file_photo2: null,
          file_photo3: null,
          file_photo4: null,
          file_photo_hover: null,
          file_background: null,
          file_logo1: null,
          file_logo2: null,
          file_product_photo: null,
          filePhotoHover:null,
          filePhotoSolarHover:null,
          filePhotoSolar:null,
          photo_hover:null,
          photo_solar:null,
          photo_solar_hover:null,
          files:[],
          collection_files:[],
          colors:[],
          tints:[],
          textures:[],
          glass_types:[],
          refinements:[],
          refinementChoices:[],
          processings:[],
          processingChoices:[],
          glassTypeChoices:[],
          glass_options:[],
          glassOptionChoices:[],
          branchs:[],
          branchChoices:[],
          productContentId:null,
          colorChoices:[],
          model_parts:[],
          tintChoices:[],
          textureChoices:[],
          gravureChoices:['op','louis','chai','lemtosh'],
          shapeChoices:['pantos','round','oval','aviator','butterfly','rectangle', 'square'],
          genderChoices:['unisex','men','women'],
          noseChoices:['integrated','silicon'],
          typeChoices:['collaboration','collection','accessoire'],
          initialForm:{
            name:"",
            brand_name:"",
            comission:null,
            product_owner:null,
            custom_fit:true,
            stock:0,
            engraving:true,
            size_nb:null,
            active:true,
            gravure_position_y:null,
            collaboration:false,
            gravure_position_x:null,
            gravure_position_x_new:null,
            gravure_position_angle:null,
            default_color:null,
            default_tint:null,
            unity_id:null,
            solar:true,
            optic:true,
            description:'',
            price:0,
            production_price:0,
            order_display:0,
            type:'collaboration',
            textures:[],
            tints:[],
            colors:[],
            photos:"",
            branchs:[],
            beta_collection:false,
            processings:[],
            refinements:[],
            glass_types:[],
            glass_options:[],
            file1:"",
            file2:"",
            file3:"",
            finishes:"",
            lenses:""
          },
          form:{
            name:"",
            engraving:true,
            product_owner:null,
            size_nb:null,
            comission:null,
            custom_fit:true,
            active:true,
            production_price:0,
            brand_name:"",
            stock:0,
            collaboration:false,
            default_color:null,
            default_tint:null,
            unity_id:null,
            gravure_position_y:null,
            beta_collection:false,
            gravure_position_x:null,
            gravure_position_x_new:null,
            gravure_position_angle:null,
            solar:true,
            optic:true,
            description:'',
            price:0,
            order_display:0,
            processings:[],
            refinements:[],
            type:'collaboration',
            textures:[],
            glass_types:[],
            glass_options:[],
            tints:[],
            colors:[],
            photos:"",
            branchs:[],
            file1:"",
            file2:"",
            file3:"",
            finishes:"",
            lenses:""
          },
          productContentForm:{
            glass:null,
            text1:"",
            title1:"",
            photo1:"",
            text2:"",
            title2:"",
            photo2:"",
            title3:"",
            spec1:"",
            spec2:"",
            spec3:"",
            photo_hover:"",
            product_photo:"",
            background:"",
            logo1:"",
            logo2:"",
            photo3:"",
            photo4:"",
            dimensions_description:"",
            mount_description:"",
            lense_description:"",
            packaging_description:"",
            hinge_description:"",
          }
      }
  },
  computed: {
    collectionWatch: function () {
      return [this.texturesSelected, this.tintsSelected, this.colorsSelected, this.branchsSelected]
    }
  },
  filters: {
      
  },
  
  watch:{
     collectionWatch: function () {
       this.branchesModels=[]
          for(let i=0;i<this.branchsSelected.length;i++)
          {
            for(let j=0;j<this.branchs.length;j++)
            {
              if(this.branchsSelected[i].value==this.branchs[j].id)
              {
               
                this.branchesModels.push(this.branchs[j])
              }
            }
          }
     
    }
  },
 
  props: [
    'glass', 
    'readOnly', 
    'glass_textures',
    'glass_colors',
    'glass_tints',
    'glass_branchs',
    'glass_glass_options',
    'glass_glass_types',
    'glass_refinements',
    'glass_processings',
    'glass_photos'
    ],
  methods:{
    formatIndex(index)
      {
        return index*1+1*1;
      },
      
      removeFile3D(index) {

        this.glass_files[index].file=null

      },
      removeBasicPhoto(index) {

        this.basic_photos.splice(index,1)
        this.file.splice(index,1)

        if(index==0 && this.basic_photos.length==0)
        {
          this.file = [false]
          this.basic_photos=[null]
        }
      },
      removeVariation(index) {
        this.product_variations.splice(index,1)
        this.variation_photos.splice(index,1)
        this.file_variation.splice(index,1)
        
      },
      removeVariationPhoto(index, indexphoto) {

        this.variation_photos[index].splice(indexphoto,1)
        this.file_variation[index].splice(indexphoto,1)
        
      },
      addVariationPhoto(index) {
        
        this.variation_photos[index].push(null)
        this.file_variation[index].push(null)
      },
      addBasicPhoto() {
        this.basic_photos.push(null)
        this.file.push(null)
      },
      file3DAdded(event, index)
      {
        var reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = () => {
            
            let b64=reader.result.split(';')
            let type=b64[0]
            let base64=b64[1]
            //base64.replace('base64,','')
            this.currentBase64=reader.result
            this.glass_files[index].file=reader.result
            
        };
      },
      fileAdded(event, index)
      {
        var reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = () => {
            
            let b64=reader.result.split(';')
            let type=b64[0]
            if(type.includes('image') || type.includes('video') || type.includes('mp4'))
            {
                let base64=b64[1]
                //base64.replace('base64,','')
                this.currentBase64=reader.result
                this.basic_photos[index]=reader.result
                
            }
            else
            {
                this.file[index]=null
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Ceci n'est pas une image !",
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                  },
                })
            }
            
        };
      },
      fileAddedVariation(event, index, indexphoto)
      {
        var reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = () => {
           
            let b64=reader.result.split(';')
            let type=b64[0]
            if(type.includes('image') || type.includes('video') || type.includes('mp4'))
            {
                let base64=b64[1]
                //base64.replace('base64,','')
                this.currentBase64=reader.result
                this.variation_photos[index][indexphoto]=reader.result
                this.file_variation[index][indexphoto]=reader.result
                
            }
            else
            {
                this.file_variation[index][indexphoto]=null
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Ceci n'est pas une image !",
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                  },
                })
            }
            
        };
      },

      async addCollectionPhoto(color,texture,texture_style,tint,branch){
        
        if(this.files[color][texture][texture_style][tint][branch])
        {
          this.files[color][texture][texture_style][tint][branch].push(null)
        }
        else
        {
          this.files.push([])
          this.files[color].push([])
          this.files[color][texture][texture_style].push([])
          this.files[color][texture][texture_style][tint].push([])
          this.files[color][texture][texture_style][tint][branch].push(null)
        }
        

        let item={}
        if(this.textures[texture].texture_styles && this.textures[texture].texture_styles.length>0)
        {
          item.texture_style="none"
        }
        else {
          item.texture_style=this.textures[texture].texture_styles[texture_style].id
        }
        item.color=this.colors[color].id
        item.tint=this.tints[tint].id
        item.texture=this.textures[texture].id
        item.branch=this.branchs[branch].id
        item.photo=null

        if(this.collection_files[color][texture][texture_style][tint][branch])
        {
          this.collection_files[color][texture][texture_style][tint][branch].push(item)
        }
        else
        {
          this.collection_files.push([])
          this.collection_files[color][texture][texture_style][tint][branch].push(item)

        }
        
        this.$forceUpdate();
      },
      async removeCollectionPhoto(color,texture,texture_style,tint,branch,index){

        if(this.collection_files[color][texture][texture_style][tint][branch][index].id!=null)
        {
          await APIRequest.delete('photos',this.collection_files[color][texture][texture_style][tint][branch][index].id)
          this.files[color][texture][texture_style][tint][branch][index]=null
          this.collection_files[color][texture][texture_style][tint][branch][index].id=null
          this.collection_files[color][texture][texture_style][tint][branch][index].photo=null
        
        }
        else
        {
          this.files[color][texture][texture_style][tint][branch][index]=null
        }

        this.$forceUpdate();
        //document.location.reload()
      },
     
      fileAddedPhoto(event,number)
      {
        var reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = () => {
            
            let b64=reader.result.split(';')
            let type=b64[0]
            if(type.includes('image') || type.includes('video') || type.includes('mp4'))
            {
                let base64=b64[1]
                //base64.replace('base64,','')
                this.currentBase64=reader.result
                if(number==1)
                {
                  this.productContentForm.photo1=this.currentBase64
                  this.file_photo1=this.currentBase64
                }
                else if(number==2)
                {
                  this.productContentForm.photo2=this.currentBase64
                  this.file_photo2=this.currentBase64
                }
                else if(number==3)
                {
                  this.productContentForm.photo3=this.currentBase64
                  this.file_photo3=this.currentBase64
                }
                else if(number==4)
                {
                  this.productContentForm.photo4=this.currentBase64
                  this.file_photo4=this.currentBase64
                }
                else if(number==5)
                {
                  this.productContentForm.photo_hover=this.currentBase64
                  this.file_photo_hover=this.currentBase64
                }
                else if(number==6)
                {
                  this.productContentForm.background=this.currentBase64
                  this.file_background=this.currentBase64
                }
                else if(number==7)
                {
                  this.productContentForm.logo1=this.currentBase64
                   this.file_logo1=this.currentBase64
                }
                else if(number==8)
                {
                  this.productContentForm.logo2=this.currentBase64
                   this.file_logo2=this.currentBase64
                }
                else if(number==9)
                {
                  this.productContentForm.product_photo=this.currentBase64
                   this.file_product_photo=this.currentBase64
                }
               
            }
            else
            {
                if(number==1)
                {
                  this.file_photo1=null
                }
                else if(number==2)
                {
                  this.file_photo2=null
                }
                else if(number==3)
                {
                  this.file_photo3=null
                }
                else if(number==4)
                {
                  this.file_photo4=null
                }
                 else if(number==5)
                {
                  this.file_photo_hover=null
                }
                 else if(number==6)
                {
                  this.file_background=null
                }
                 else if(number==7)
                {
                  this.file_logo1=null
                }
                 else if(number==8)
                {
                  this.file_logo2=null
                }
                else if(number==9)
                {
                  this.file_product_photo=null
                }
                
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Ceci n'est pas une image !",
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                  },
                })
            }
            
        };
      },
      fileAddedPhotoHover(event)
      {
        var reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = () => {
            
            let b64=reader.result.split(';')
            let type=b64[0]
            if(type.includes('image') || type.includes('video') || type.includes('mp4'))
            {
                let base64=b64[1]
                //base64.replace('base64,','')
                this.currentBase64=reader.result
                this.photo_hover=this.currentBase64
                this.filePhotoHover=this.currentBase64
            }
            else
            {
                this.photo_hover=null
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Ceci n'est pas une image !",
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                  },
                })
            }
        };
      },
      fileAddedPhotoSolar(event)
      {
        var reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = () => {
            
            let b64=reader.result.split(';')
            let type=b64[0]
            if(type.includes('image') || type.includes('video') || type.includes('mp4'))
            {
                let base64=b64[1]
                //base64.replace('base64,','')
                this.currentBase64=reader.result
                this.photo_solar=this.currentBase64
                this.filePhotoSolar=this.currentBase64
            }
            else
            {
                this.photo_solar=null
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Ceci n'est pas une image !",
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                  },
                })
            }
        };
      },
      fileAddedPhotoSolarHover(event)
      {
        var reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.onload = () => {
            
            let b64=reader.result.split(';')
            let type=b64[0]
            if(type.includes('image') || type.includes('video') || type.includes('mp4'))
            {
                let base64=b64[1]
                //base64.replace('base64,','')
                this.currentBase64=reader.result
                this.photo_solar_hover=this.currentBase64
                this.filePhotoSolarHover=this.currentBase64
            }
            else
            {
                this.photo_solar_hover=null
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Ceci n'est pas une image !",
                    icon: 'AlertCircleIcon',
                    variant: 'warning',
                  },
                })
            }
        };
      },
       removePhotoCollaboration(number)
      {
        if(number==1)
        {
          this.file_photo1=null
          this.productContentForm.photo1=null
        }
        else if(number==2)
        {
          this.file_photo2=null
          this.productContentForm.photo2=null
        }
        else if(number==3)
        {
          this.file_photo3=null
          this.productContentForm.photo3=null
        }
        else if(number==4)
        {
          this.file_photo4=null
          this.productContentForm.photo4=null
        }
        else if(number==5)
        {
          this.file_photo_hover=null
          this.productContentForm.photo_hover=null
        }
        else if(number==6)
        {
          this.file_background=null
          this.productContentForm.background=null
        }
        else if(number==7)
        {
          this.file_logo1=null
          this.productContentForm.logo1=null
        }
        else if(number==8)
        {
          this.file_logo2=null
          this.productContentForm.logo2=null
        }
        else if(number==9)
        {
          this.file_product_photo=null
          this.productContentForm.product_photo=null
        }
      },
      removePhotoSolar()
      {
        this.filePhotoSolar=null
        this.photo_solar=null
      },
      removePhotoSolarHover()
      {
        this.filePhotoSolarHover=null
        this.photo_solar_hover=null
      },
      removePhotoHover()
      {
        this.filePhotoHover=null
        this.photo_hover=null
      },
      addProductVariation()
      {
        if(this.product_variations==null)
        {
          this.product_variations=[]
        }
        let item = {}
        item.name=''
        item.price=0
        item.stock=0
        item.photos=[]
        this.product_variations.push(item)
        this.file_variation.push([])
        this.variation_photos.push([])

      },
      async getTextures() {
        let textures = await APIRequest.list('textures')
        this.textures=textures[0];
        this.textureChoices=Utils.formatSelectWithImg(this.textures,'name','id','photo')
        
      },
      async getBranches() {
        let branchs = await APIRequest.list('branches')
        this.branchs=branchs[0];
        this.branchChoices=Utils.formatSelect(this.branchs,'name','id')
       
      },
      async getGlassTypes() {
        let glass_types = await APIRequest.list('glass-types')
        this.glass_types=glass_types[0];
        this.glassTypeChoices=Utils.formatSelect(this.glass_types,'name','id')
       
      },
      formatSelect(item) {
        
        if(item=='colors')
        {
          this.colorsSelected=[]
          for(let j=0;j<this.glass_colors.length;j++)
          {
            for(let i=0;i<this.colorChoices.length;i++)
            {
              if(this.glass_colors[j].id == this.colorChoices[i].value)
              {
                this.colorsSelected.push(this.colorChoices[i])
              }
            }
          }
        }
        if(item=='textures')
        {
          this.texturesSelected=[]
         
          for(let j=0;j<this.glass_textures.length;j++)
          {
            
            for(let i=0;i<this.textureChoices.length;i++)
            {
              
              if(this.glass_textures[j].id == this.textureChoices[i].value)
              {
                this.texturesSelected.push(this.textureChoices[i])
                if(!this.textureStylesSelected[j]) {
                  this.textureStylesSelected.push([])
                }
                if(this.glass_textures[j].texture_styles && this.glass_textures[j].texture_styles.length>0) {
                  this.textureStylesSelected[j]=this.glass_textures[j].texture_styles
                }
                else {
                  this.textureStylesSelected[j]=["none"]
                }
              }
         
            }
          }
        }
        if(item=='tints')
        {
          this.tintsSelected=[]
          for(let j=0;j<this.glass_tints.length;j++)
          {
            for(let i=0;i<this.tintChoices.length;i++)
            {
              if(this.glass_tints[j].id == this.tintChoices[i].value)
              {
                this.tintsSelected.push(this.tintChoices[i])
              }
            }
          }
        }
        if(item=='branchs')
        {
          this.branchsSelected=[]
          for(let j=0;j<this.glass_branchs.length;j++)
          {
            for(let i=0;i<this.branchChoices.length;i++)
            {
              if(this.glass_branchs[j].id == this.branchChoices[i].value)
              {
                this.branchsSelected.push(this.branchChoices[i])
              }
            }
          }
        }
        if(item=='glass_types')
        {
          this.glassTypesSelected=[]
          for(let j=0;j<this.glass_glass_types.length;j++)
          {
            for(let i=0;i<this.glassTypeChoices.length;i++)
            {
              if(this.glass_glass_types[j].id == this.glassTypeChoices[i].value)
              {
                this.glassTypesSelected.push(this.glassTypeChoices[i])
              }
            }
          }
        }
        if(item=='glass_options')
        {
          this.glassOptionsSelected=[]
          for(let j=0;j<this.glass_glass_options.length;j++)
          {
            for(let i=0;i<this.glassOptionChoices.length;i++)
            {
              if(this.glass_glass_options[j].id == this.glassOptionChoices[i].value)
              {
                this.glassOptionsSelected.push(this.glassOptionChoices[i])
              }
            }
          }
        }
        if(item=='refinements')
        {
          this.refinementsSelected=[]
          for(let j=0;j<this.glass_refinements.length;j++)
          {
            for(let i=0;i<this.refinementChoices.length;i++)
            {
              if(this.glass_refinements[j].id == this.refinementChoices[i].value)
              {
                this.refinementsSelected.push(this.refinementChoices[i])
              }
            }
          }
        }
        if(item=='processings')
        {
          this.processingsSelected=[]
          for(let j=0;j<this.glass_processings.length;j++)
          {
            for(let i=0;i<this.processingChoices.length;i++)
            {
              if(this.glass_processings[j].id == this.processingChoices[i].value)
              {
                this.processingsSelected.push(this.processingChoices[i])
              }
            }
          }
        }

      },
      async getUserBrands()
      {
        this.user_brands=await Auth.list_users_by_role('ROLE_CREATOR')
        this.userBrandChoices=Utils.formatSelect(this.user_brands,'name','id')
      },
      async getGlassOptions() {
        let glass_options = await APIRequest.list('glass-options')
        this.glass_options=glass_options[0];
        this.glassOptionChoices=Utils.formatSelect(this.glass_options,'name','id')
       
      },
      async getRefinements() {
        let refinements = await APIRequest.list('refinements')
        this.refinements=refinements[0];
        this.refinementChoices=Utils.formatSelect(this.refinements,'name','id')
        
      },
      async getProcessings() {
        let processings = await APIRequest.list('processings')
        this.processings=processings[0];
        this.processingChoices=Utils.formatSelect(this.processings,'name','id')
       
      },
      async getTints() {
        let tints = await APIRequest.list('tints')
        this.tints=tints[0];
        this.tintChoices=Utils.formatSelectWithImg(this.tints,'name','id','photo')
        
      },
      async getColors() {
        let colors = await APIRequest.list('colors')
        this.colors=colors[0];
        this.colorChoices=Utils.formatSelectWithImg(this.colors,'name','id','photo')
        
      },
      async getModelParts() {
        let request='?glass='+this.glass.id
        let model_parts = await APIRequest.list_with_request('model-parts', request)
        
        this.model_parts = model_parts[0]
      },
      async createGlass()
      {
        this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.loading=true
          this.form.photos=''
          for(let j=0;j<this.basic_photos.length;j++)
          {
            this.form.photos+="['"+this.basic_photos[j]+"']";
          }
          let datas=this.form
      
          try {
            
              
              datas.branchs=[]
              datas.glass_types=[]
              datas.glass_options=[]
              datas.refinements=[]
              datas.processings=[]

           
          
              for(let j=0;j<this.branchsSelected.length;j++)
              {
                let item={}
                item.id=this.branchsSelected[j].value
                datas.branchs.push(item)
              }

              for(let j=0;j<this.glassTypesSelected.length;j++)
              {
                let item={}
                item.id=this.glassTypesSelected[j].value
                datas.glass_types.push(item)
              }
            

              for(let j=0;j<this.glassOptionsSelected.length;j++)
              {
                let item={}
                item.id=this.glassOptionsSelected[j].value
                datas.glass_options.push(item)
              }
            
            
              for(let j=0;j<this.refinementsSelected.length;j++)
              {
                let item={}
                item.id=this.refinementsSelected[j].value
                datas.refinements.push(item)
              }
              
            
              for(let j=0;j<this.processingsSelected.length;j++)
              {
                let item={}
                item.id=this.processingsSelected[j].value
                datas.processings.push(item)
            
              }
          
            

              console.log('test')
              console.log(this.form.default_color)
              if(this.form.default_color!=null)
              {
                datas.default_color={ id: this.form.default_color.value}
              }
              if(this.form.default_tint!=null)
              {
                datas.default_tint={ id: this.form.default_tint.value}
              }
            
            datas.gravure_position_x=this.form.gravure_position_x
            
            datas.photo_solar=this.photo_solar
            datas.photo_solar_hover=this.photo_solar_hover
            datas.photo_hover=this.photo_hover
            datas.gender=this.gender
            datas.shape=this.shape
            datas.nose=this.nose

            if(this.form.product_owner!=null)
              {
                datas.product_owner={ id: this.form.product_owner.value}
              }

            
        
            let glass = await APIRequest.create('glasses',datas)
            
            for(let i=0;i<this.glass_dimensions.length;i++) {
              if(this.glass_dimensions[i].width!='' || this.glass_dimensions[i].height!='') {
                let params={}
                params.glass={}
                params.glass.id=glass.id
                params.size={}
                params.size.id=this.glass_dimensions[i].size
                params.width=this.glass_dimensions[i].width
                params.height=this.glass_dimensions[i].height
                await APIRequest.create('glass-dimensions',params)
              }
            }
            
            for(let i=0;i<this.glass_files.length;i++) {
              if(this.glass_files[i].file!=null) {
                let params={}
                params.glass={}
                params.glass.id=glass.id
                params.size={}
                params.size.id=this.glass_dimensions[i].size
                params.file=this.glass_files[i].file
                await APIRequest.create('glass-3d-files',params)
              }
            }

            if(this.form.type!='accessoire') {
              this.productContentForm.glass={}
              this.productContentForm.glass.id=glass.id
              await APIRequest.create('product-contents',this.productContentForm)

            
            }
            
            if(glass.type=="collection")
            {
              
            }
            else
            {
              
              if(this.hasVariations==true)
              {
                let data={}
                data.glass={}
                data.glass.id=glass.id
                data.name="Variation"
                let product_variation = await APIRequest.create('product-variations',data)
                for(let k=0;k<this.product_variations.length;k++)
                {
                  this.product_variations[k].photos=''
                  for(let j=0;j<this.variation_photos[k].length;j++)
                  {
                    this.product_variations[k].photos+="['"+this.variation_photos[k][j]+"']";
                  }
              
                  let data={}
                  data=this.product_variations[k]
                  data.product_variation={}
                  data.product_variation.id=product_variation.id
                  await APIRequest.create('product-variation-items',data)
                  
                }
              }
            }
            
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Le produit a bien été créé !',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({name:'glasses'})
          } catch (error) {
            this.loading=false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Une erreur est survenue.',
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
            
          }
        }
        })
      },
      async updateGlass()
      {
         this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.loading=true
          this.form.photos='';
        
          for(let j=0;j<this.basic_photos.length;j++)
          {
            this.form.photos+="['"+this.basic_photos[j]+"']";
          }
          
          var datas=this.form
          
          
          
            datas.branchs=[]
            datas.glass_types=[]
            datas.glass_options=[]
            datas.refinements=[]
            datas.processings=[]

            

           

            for(let j=0;j<this.branchsSelected.length;j++)
            {
              let item={}
              item.id=this.branchsSelected[j].value
              datas.branchs.push(item)
            }

            for(let j=0;j<this.glassTypesSelected.length;j++)
            {
              let item={}
              item.id=this.glassTypesSelected[j].value
              datas.glass_types.push(item)
            }

            for(let j=0;j<this.glassOptionsSelected.length;j++)
            {
              let item={}
              item.id=this.glassOptionsSelected[j].value
              datas.glass_options.push(item)
            }
          
          
            for(let j=0;j<this.refinementsSelected.length;j++)
            {
              let item={}
              item.id=this.refinementsSelected[j].value
              datas.refinements.push(item)
            }
            
          
            for(let j=0;j<this.processingsSelected.length;j++)
            {
              let item={}
              item.id=this.processingsSelected[j].value
              datas.processings.push(item)
          
            }
        
          

            console.log('test')
              console.log(this.form.default_color)
              if(this.form.default_color!=null)
              {
                datas.default_color={ id: this.form.default_color.value}
              }
              if(this.form.default_tint!=null)
              {
                datas.default_tint={ id: this.form.default_tint.value}
              }
              if(this.form.product_owner!=null)
              {
                datas.product_owner={ id: this.form.product_owner.value}
              }

          

          datas.photo_solar=this.photo_solar
          datas.photo_solar_hover=this.photo_solar_hover
          datas.photo_hover=this.photo_hover
          datas.gender=this.gender
          datas.shape=this.shape
          datas.nose=this.nose

          console.log('updateeee glasssss')
          console.log(datas.collaboration)
          console.log(this.form.collaboration)

          delete datas['product_variations']
          delete datas['product_content']

          datas.gravure_position_x=this.form.gravure_position_x

          let glass = await APIRequest.update('glasses',this.glass.id,datas)


          for(let i=0;i<this.glass_dimensions.length;i++) {
              if(this.glass_dimensions[i].width!='' || this.glass_dimensions[i].height!='') {
                let params={}
                params.glass={}
                params.glass.id=glass.id
                params.size={}
                params.size.id=this.glass_dimensions[i].size
                params.width=this.glass_dimensions[i].width
                params.height=this.glass_dimensions[i].height

                if(this.glass_dimensions[i].id) {
                  await APIRequest.update('glass-dimensions',this.glass_dimensions[i].id,params)
                }
                else {
                  await APIRequest.create('glass-dimensions',params)
                }
               
              }
            }
            
            for(let i=0;i<this.glass_files.length;i++) {
              if(this.glass_files[i].file!=null) {
                let params={}
                params.glass={}
                params.glass.id=glass.id
                params.size={}
                params.size.id=this.glass_dimensions[i].size
                params.file=this.glass_files[i].file
                if(this.glass_files[i].id) {
                  await APIRequest.update('glass-3d-files',this.glass_files[i].id,params)
                }
                else {
                  await APIRequest.create('glass-3d-files',params)
                }
                
              }
            }

          

          try {

            if(this.form.type!="accessoire") {

            
              this.productContentForm.glass={}
              this.productContentForm.glass.id=this.glass.id
              
              if(this.productContentId!=null)
              {
                await APIRequest.update('product-contents',this.productContentId, this.productContentForm)
              }
              else
              {
                await APIRequest.create('product-contents', this.productContentForm)
              }

            }

            if(glass.type=="collection")
            {
              
            }
            else
            {
              if(this.hasVariations==true)
              {

                // check if exist
                let data={}
                data.name = "Variation"
                data.glass={}
                data.glass.id=glass.id
                
                data.name="Variation"
                delete data['product_variation_items']
              
                if(this.productVariationId!=null)
                {
                  var product_variation = await APIRequest.update('product-variations', this.productVariationId,data)
                }
                else
                {
                  var product_variation = await APIRequest.create('product-variations',data)
                }
                
                for(let k=0;k<this.product_variations.length;k++)
                {       
                  this.product_variations[k].photos=''
                  for(let j=0;j<this.variation_photos[k].length;j++)
                  {
                    
                    this.product_variations[k].photos+="['"+this.variation_photos[k][j]+"']";
                  }

                  let data={}
                  data=this.product_variations[k]
                  if(this.product_variations[k].id)
                  {
                    await APIRequest.update('product-variation-items',this.product_variations[k].id,data) 
                  }
                  else
                  {
                    data.product_variation={}
                    data.product_variation.id=product_variation.id
                    await APIRequest.create('product-variation-items',data) 
                  }
                  
                  
                }
              }
              
            }
            
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Le produit a bien été modifié !',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            this.$router.push({name:'glasses'})
          } catch (error) {
            console.log("error")
            console.log(error)
            this.loading=false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Une erreur est survenue.',
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
            
          }
        }
         })
      },
      async getSizes() {
        let sizes=await APIRequest.list('sizes')
        this.sizes=sizes[0]

        for(let i=0;i<this.sizes.length;i++) {
          
          let item={}
          if(this.glass!=null) {
            let glass_dimension=await APIRequest.list('glass-dimensions', null, null, 10, null, this.glass.id, null, null,null, null, this.sizes[i].id)
            if(glass_dimension[0].length>0) {
              item.id=glass_dimension[0][0].id
              item.width=glass_dimension[0][0].width
              item.height=glass_dimension[0][0].height
            }
            else {
              item.width=''
              item.height=''
            }
          }
          else {
            item.width=''
            item.height=''
          }
          item.size=this.sizes[i].id
          
          this.glass_dimensions.push(item)

          item={}
          item.size=this.sizes[i].id
          if(this.glass!=null) {
            let glass_file=await APIRequest.list('glass-3d-files', null, null, 10, null, this.glass.id, null, null,null, null, this.sizes[i].id)
            if(glass_file[0].length>0) {
              item.id=glass_file[0][0].id
              item.file=glass_file[0][0].file
            }
            else {
             item.file=null
            }
          }
          else {
           item.file=null
          }
          this.glass_files.push(item)
        }

      },
      async deleteModelPart(id)
    {
      this.loading=true
      try {
        await APIRequest.delete('model-parts',id)
        await this.getModelParts()
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Le model part a été supprimé !',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        
      } catch (error) {
        this.loading=false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Une erreur est survenue.',
            icon: 'AlertCircleIcon',
            variant: 'warning',
          },
        })
        
      }
      
    }
      
  },
  async mounted(){

    await this.getTextures()
    await this.getTints()
    await this.getColors()
    await this.getBranches()
    await this.getGlassTypes()
    await this.getGlassOptions()
    await this.getRefinements()
    await this.getProcessings()
    await this.getSizes()
    await this.getUserBrands()

    if(this.glass!=null)
    {

      await this.getModelParts()
      this.photo_hover=this.glass.photo_hover
      this.photo_solar_hover=this.glass.photo_solar_hover
      this.photo_solar=this.glass.photo_solar
      this.gender=this.glass.gender
      this.shape=this.glass.shape
      this.nose=this.glass.nose
      this.form=this.glass
      let product_variations = this.glass.product_variations

      if(this.glass.default_color!=null)
      {
          for(let p=0;p<this.colorChoices.length;p++)
          {
            if(this.colorChoices[p].value==this.glass.default_color.id)
            {
              this.form.default_color=this.colorChoices[p]
            }
          }
          
      }

      if(this.glass.default_tint!=null)
      {
          for(let p=0;p<this.tintChoices.length;p++)
          {
            if(this.tintChoices[p].value==this.glass.default_tint.id)
            {
              this.form.default_tint=this.tintChoices[p]
            }
          }
          
      }

      if(this.glass.product_owner!=null)
      {
          for(let p=0;p<this.userBrandChoices.length;p++)
          {
            if(this.userBrandChoices[p].value==this.glass.product_owner.id)
            {
              this.form.product_owner=this.userBrandChoices[p]
            }
          }
          
      }
      if(product_variations.length>0)
      {
        this.product_variations=[]
        this.productVariationId=product_variations[0].id
        this.hasVariations=true

        this.file_variation=Array()
        for(let p=0;p<product_variations[0].product_variation_items.length;p++)
        {
          let item={}
          item.id=product_variations[0].product_variation_items[p].id
          item.name=product_variations[0].product_variation_items[p].name
          item.price=product_variations[0].product_variation_items[p].price
          item.stock=product_variations[0].product_variation_items[p].stock
          item.photos=""
          this.product_variations.push(item)
          
           this.file_variation.push(Array())
           this.variation_photos.push(Array())
           
           if(product_variations[0].product_variation_items[p].photos!='')
           {
              
              let variation_photos=product_variations[0].product_variation_items[p].photos;

              for(let i=0;i<variation_photos.length;i++)
              {
                let photo=variation_photos[i]
                if(photo!='')
                {
                  this.variation_photos[p].push(photo)
                  this.file_variation[p].push(true)
                }
              }
           }
          
        }
        
      }

      this.basic_photos=this.glass_photos;
     
      this.file=this.glass_photos
     
      let product_contents=await APIRequest.list('product-contents', null, null, 100, 1, this.glass.id)
      if(product_contents[0].length>0)
      {
        this.productContentId=product_contents[0][0].id
        this.productContentForm=product_contents[0][0]
        this.file_photo1 = product_contents[0][0].photo1
        this.file_photo2 = product_contents[0][0].photo2
        this.file_photo3 = product_contents[0][0].photo3
        this.file_photo4 = product_contents[0][0].photo4
        this.file_background = product_contents[0][0].background
        this.file_photo_hover = product_contents[0][0].photo_hover
        this.file_logo1 = product_contents[0][0].logo1
        this.file_logo2 = product_contents[0][0].logo2
        this.file_product_photo = product_contents[0][0].product_photo
      }

      
       
        // if(this.glass_colors!=null )
        // {
        //   console.log('colors')
        //   console.log(this.glass_colors)
        //   this.formatSelect('colors')
        // }
        // if(this.glass_textures!=null)
        // {
        //   this.formatSelect('textures')
        // }
        // if(this.glass_tints!=null)
        // {
        //   this.formatSelect('tints')
        // }
        if(this.glass_branchs!=null)
        {
          this.formatSelect('branchs')
        }
        if(this.glass_glass_types!=null)
        {
          this.formatSelect('glass_types')
        }
        if(this.glass_glass_options!=null)
        {
          this.formatSelect('glass_options')
        }
        if(this.glass_refinements!=null)
        {
          this.formatSelect('refinements')
        }
        if(this.glass_processings!=null)
        {
          this.formatSelect('processings')
        }
      
    
      

      
      this.edition=true
      
    }
    this.loaded=true
    this.loading=false
  }

}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>